import React from 'react'

function xd() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36.001" height="35.097" viewBox="0 0 36.001 35.097">
      <path id="adobe-xd" d="M29.625.451H6.374A6.367,6.367,0,0,0,0,6.826V29.173a6.367,6.367,0,0,0,6.375,6.375h23.25A6.367,6.367,0,0,0,36,29.173V6.826A6.367,6.367,0,0,0,29.625.451Zm4.858,28.137a5.428,5.428,0,0,1-5.426,5.46H6.943A5.452,5.452,0,0,1,1.5,28.6V7.395A5.452,5.452,0,0,1,6.943,1.951H29.038a5.445,5.445,0,0,1,5.444,5.443ZM14.232,16.875l4.2,8.069c.077.123.03.24-.089.24H15.732a.282.282,0,0,1-.31-.193c-.96-1.98-1.94-3.96-2.942-6.087h-.028q-1.384,3.073-2.854,6.1a.28.28,0,0,1-.269.163H6.855c-.152,0-.163-.123-.087-.21l4.107-7.829L6.9,9.212C6.808,9.089,6.9,9,6.99,9H9.569c.152,0,.211.03.27.163q1.436,2.994,2.807,6.018h.028c.873-1.98,1.835-4.037,2.76-6,.077-.123.123-.2.27-.2h2.413c.123,0,.17.1.095.211Zm4.939,2.444A5.865,5.865,0,0,1,25.183,13.1a6.643,6.643,0,0,1,.78.03V9.153A.15.15,0,0,1,26.115,9h2.367c.123,0,.153.046.153.117V22.893a10.3,10.3,0,0,0,.077,1.464c0,.095-.03.123-.123.171a8.963,8.963,0,0,1-3.838.867c-3.258.017-5.579-1.992-5.579-6.076Zm6.793-3.838a2.311,2.311,0,0,0-.869-.152c-1.891,0-3.21,1.458-3.21,3.873,0,2.76,1.347,3.867,3.046,3.867a2.753,2.753,0,0,0,1.032-.163Z" transform="translate(0.001 -0.451)" />
    </svg>

  )
}

export default xd
