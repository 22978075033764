import React from 'react'

function photoshop() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={36} height="35.098" viewBox="0 0 36 35.098">
      <path id="adobe-photoshop" d="M0,.451v35.1H36V.451Zm1.5,1.5h33v32.1H1.5ZM8.7,8.672c0-.1.211-.175.334-.175q1.933-.082,3.868-.077c4.16,0,5.777,2.279,5.777,5.2,0,3.815-2.765,5.45-6.153,5.45-.574,0-.767-.029-1.165-.029V24.8c0,.123-.047.175-.17.175H8.871c-.123,0-.17-.047-.17-.17Zm2.661,7.97c.345.024.621.024,1.218.024,1.752,0,3.4-.621,3.4-2.994,0-1.9-1.183-2.871-3.187-2.871-.592,0-1.16.024-1.435.047Zm12.914-1.788c-1.189,0-1.587.6-1.587,1.09,0,.544.27.915,1.857,1.735,2.35,1.143,3.094,2.226,3.094,3.837,0,2.4-1.834,3.685-4.306,3.685a6.58,6.58,0,0,1-3.076-.645c-.093-.047-.117-.123-.117-.246v-2.2c0-.146.071-.2.17-.123a5.513,5.513,0,0,0,3.017.891c1.189,0,1.682-.493,1.682-1.165,0-.544-.345-1.014-1.852-1.8-2.133-1.019-3.024-2.057-3.024-3.791,0-1.934,1.512-3.539,4.131-3.539a7.161,7.161,0,0,1,2.7.416.327.327,0,0,1,.146.3v2.057c0,.123-.077.2-.223.146a5.418,5.418,0,0,0-2.613-.645Z" transform="translate(0 -0.451)" />
    </svg>

  )
}

export default photoshop
