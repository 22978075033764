import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { getTextColor } from "../utils/functions";
import Logo from "../icons/Logo";
function Nav({ bgColor, themeColor }) {
  const [colorChange, setColorchange] = useState(false);

  let lastScrollTop = 0;
  const changeNavbarColor = () => {
    var st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
    lastScrollTop = st <= 0 ? 0 : st;
  };
  window.addEventListener("scroll", changeNavbarColor);

  const changeNavColor = () => {
    let root = document.querySelector(":root");
    root.style.setProperty(
      "--backgroundColor",
      bgColor ? (colorChange ? "#fff" : bgColor) : "#fff"
    );
    root.style.setProperty("--themeColor", themeColor ? themeColor : "#FFCE09");
    root.style.setProperty("--blur", "10px");
    root.style.setProperty(
      "--textColor",
      bgColor ? (colorChange ? "#373737" : getTextColor(bgColor)) : "#373737"
    );
  };

  useEffect(() => {
    changeNavColor();
  }, [themeColor, colorChange]); //eslint-disable-line

  return (
    <motion.nav
      className={colorChange ? "fixed" : ""}
      style={{ backgroundColor: bgColor ? bgColor : "transparent" }}
    >
      <div className="main-container">
        <NavLink to="/">
          <Logo color={colorChange ? "#373737" : getTextColor(bgColor)} />
        </NavLink>
        <ul>
          <li>
            <NavLink to="/" exact={true} activeClassName="active">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/portfolio">Portfolio</NavLink>
          </li>
          <li>
            <NavLink to="/my-story">My Story</NavLink>
          </li>
          <li>
            <NavLink to="/lets-talk">Let's Talk</NavLink>
          </li>
        </ul>
      </div>
    </motion.nav>
  );
}

export default Nav;
