import React from 'react'

function Logo({ color = "#393939" }) {
  return (
    <svg id="Group_3" data-name="Group 3" xmlns="http://www.w3.org/2000/svg" width="33.418" height="34.371" viewBox="0 0 33.418 34.371">
      <path id="Path_4" data-name="Path 4" d="M31.607,24.051A12.026,12.026,0,1,1,31.607,0H45.636a1.707,1.707,0,0,1,0,3.414H31.607a8.612,8.612,0,0,0,0,17.223,1.707,1.707,0,0,1,0,3.414Z" transform="translate(-13.925)" fill={color} />
      <path id="Path_5" data-name="Path 5" d="M15.736,59.781H1.707a1.707,1.707,0,0,1,0-3.414H15.736a8.612,8.612,0,1,0,0-17.223,1.707,1.707,0,1,1,0-3.414,12.026,12.026,0,1,1,0,24.051Z" transform="translate(0 -25.41)" fill={color} />
    </svg>
  )
}

export default Logo
