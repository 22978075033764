import React from 'react'

function laravel() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34.969" height="36.002" viewBox="0 0 34.969 36.002">
      <path id="laravel" d="M35.466,8.145a.546.546,0,0,1,.021.149v7.725a.563.563,0,0,1-.283.489l-6.485,3.735v7.4a.567.567,0,0,1-.282.489L14.9,35.925a.474.474,0,0,1-.1.041c-.012,0-.024.012-.036.015a.522.522,0,0,1-.288,0,.193.193,0,0,1-.044-.018.649.649,0,0,1-.1-.038L.8,28.133a.564.564,0,0,1-.283-.489V4.461a.522.522,0,0,1,.021-.147c0-.018.015-.032.021-.048A.553.553,0,0,1,.6,4.179a.182.182,0,0,1,.035-.05c.018-.024.032-.046.05-.068s.038-.027.055-.041S.776,3.984.8,3.969h0L7.566.075a.563.563,0,0,1,.563,0l6.768,3.9h0a.579.579,0,0,1,.061.05l.055.041c.019.021.032.044.05.068a.348.348,0,0,1,.038.05.41.41,0,0,1,.036.087.367.367,0,0,1,.019.048.475.475,0,0,1,.021.147V18.94l5.64-3.246V8.29a.522.522,0,0,1,.021-.147c0-.017.013-.032.019-.048a.73.73,0,0,1,.036-.088c.011-.018.027-.032.038-.05s.032-.045.05-.065a.383.383,0,0,1,.055-.042A.534.534,0,0,1,21.1,7.8h0l6.769-3.9a.563.563,0,0,1,.563,0L35.2,7.8c.024.015.041.032.063.046s.038.027.054.042.033.044.051.066a.39.39,0,0,1,.036.05.408.408,0,0,1,.036.088.24.24,0,0,1,.022.05Zm-1.11,7.548V9.268L31.989,10.63l-3.273,1.884v6.425l5.64-3.246ZM27.589,27.318V20.887L24.37,22.725l-9.191,5.247v6.489l12.409-7.143ZM1.643,5.436V27.318l12.409,7.143V27.972L7.569,24.305l0,0h0c-.021-.015-.038-.032-.06-.046s-.036-.027-.053-.041l0,0a.355.355,0,0,1-.046-.058A.309.309,0,0,1,7.36,24.1h0c-.012-.021-.019-.046-.03-.071a.6.6,0,0,1-.027-.065.735.735,0,0,1-.012-.086.449.449,0,0,1-.009-.061V8.683L4.009,6.8,1.642,5.436Zm6.2-4.221L2.206,4.461l5.64,3.246,5.637-3.246ZM10.78,21.473l3.273-1.884V5.436L11.683,6.8,8.41,8.684V22.836l2.37-1.364ZM28.153,5.046l-5.64,3.246,5.64,3.244L33.791,8.29,28.153,5.046Zm-.564,7.469-3.273-1.884L21.947,9.269v6.425l3.273,1.884,2.369,1.362V12.514ZM14.614,27l8.271-4.722,4.134-2.358-5.636-3.244L14.9,20.4,8.985,23.81,14.613,27Z" transform="translate(-0.518 0)" />
    </svg>

  )
}

export default laravel
