import React from 'react'

function PortfolioPlaceholder() {
  return (
    <div className="card feature-portfolio ph">
      <div className="content">
        <div className="placeholder h3" style={{ width: '80%' }}></div>
        <div className="placeholder h3" style={{ width: '50%' }}></div>
        <div className="placeholder p" style={{ width: '60%' }}></div>
        <div className="placeholder btn" style={{ width: '60%' }}></div>
      </div>
      <div className="portfolio-image placeholder"  >
      </div>
    </div>
  )
}

export default PortfolioPlaceholder
