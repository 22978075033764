import React from 'react'

function jquery() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29.919" height="29.919" viewBox="0 0 29.919 29.919">
      <path id="jquery" d="M23.579,14.025a7.4,7.4,0,0,1-5.435-2.25,7.4,7.4,0,0,1-2.25-5.435,6.579,6.579,0,0,1,1.052-3.55A9.317,9.317,0,0,1,19.635,0q-.117.293-.365.906t-.38.935q-.132.322-.351.862t-.322.877q-.1.337-.219.745t-.175.716a2.988,2.988,0,0,0-.058.57,6.328,6.328,0,0,0,1.885,4.66,6.334,6.334,0,0,0,4.66,1.885,3.081,3.081,0,0,0,.57-.058q.308-.058.716-.175t.745-.219q.337-.1.877-.322l.862-.351.935-.38.906-.365a9.294,9.294,0,0,1-2.79,2.688A6.59,6.59,0,0,1,23.579,14.025ZM21.5,18.7a25.823,25.823,0,0,0,3.345-.2q1.562-.2,4.135-.731A10.693,10.693,0,0,1,25.1,20.541a12.393,12.393,0,0,1-4.879.964A11.754,11.754,0,0,1,8.415,9.73a12.467,12.467,0,0,1,.964-4.908A10.693,10.693,0,0,1,12.155.935q-.526,2.571-.731,4.135a25.835,25.835,0,0,0-.2,3.345,9.851,9.851,0,0,0,1.4,5.113A10.6,10.6,0,0,0,16.392,17.3,9.851,9.851,0,0,0,21.5,18.7ZM16.83,26.18a41.438,41.438,0,0,0,9.35-.935,13.418,13.418,0,0,1-4.967,3.477,16.3,16.3,0,0,1-6.252,1.2A14.913,14.913,0,0,1,2,22.469,14.687,14.687,0,0,1,0,14.96,16.321,16.321,0,0,1,1.2,8.707,13.414,13.414,0,0,1,4.675,3.74a41.438,41.438,0,0,0-.935,9.35,12.8,12.8,0,0,0,1.037,5.084,12.856,12.856,0,0,0,6.968,6.969A12.817,12.817,0,0,0,16.83,26.18Z" />
    </svg>

  )
}

export default jquery
