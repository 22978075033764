import React from 'react';

function ArrowDown({ className }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="26.083" height="33.028" viewBox="0 0 26.083 33.028">
      <g id="arrow-down" transform="translate(-808.913 -799.285)">
        <path id="Path_14" data-name="Path 14" d="M0,7.287,11.381,0,21.924,7.287" transform="translate(832.924 808.658) rotate(180)" fill="none" stroke="#ffce09" strokeLinecap="round" strokeWidth={3} />
        <path id="Path_15" data-name="Path 15" d="M0,7.287,11.381,0,21.924,7.287" transform="translate(832.924 819.585) rotate(180)" fill="none" stroke="#ffce09" strokeLinecap="round" strokeWidth={3} />
        <path id="Path_16" data-name="Path 16" d="M0,7.287,11.381,0,21.924,7.287" transform="translate(832.924 830.511) rotate(180)" fill="none" stroke="#ffce09" strokeLinecap="round" strokeWidth={3} />
      </g>
    </svg>
  )
}

export default ArrowDown;
