import React from 'react'

function figma() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.365" height="32.048" viewBox="0 0 21.365 32.048">
      <path id="figma" d="M16.683,16.024a5.341,5.341,0,1,1,5.341,5.341A5.341,5.341,0,0,1,16.683,16.024ZM6,26.707a5.341,5.341,0,0,1,5.341-5.341h5.341v5.341A5.341,5.341,0,0,1,6,26.707ZM16.683,0V10.683h5.341A5.341,5.341,0,1,0,22.024,0ZM6,5.341a5.341,5.341,0,0,0,5.341,5.341h5.341V0H11.341A5.341,5.341,0,0,0,6,5.341ZM6,16.024a5.341,5.341,0,0,0,5.341,5.341h5.341V10.683H11.341A5.341,5.341,0,0,0,6,16.024Z" transform="translate(-6)" />
    </svg>

  )
}

export default figma

