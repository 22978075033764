import React, { useState, useEffect } from 'react';
import firebase from '../utils/firebase';
import { Formik } from 'formik';
import Input from '../components/Elements/Input';
import SelectDropdown from '../components/Elements/SelectDropdown';
import CreatableDropdown from '../components/Elements/CreatableDropdown';
import Axios from 'axios';
import DropIt from '../components/Elements/DropIt';
import Button from '../components/Buttons/Button';
import ImageInput from '../components/Elements/ImageInput';

function Admin() {
  const [categories, setCategories] = useState([]);
  const [typographyImages, setTypographyImages] = useState([]);
  const [screenshotImages, setScreenshotsImages] = useState([]);
  const [featuredImages, setFeaturedImages] = useState([]);
  const [structureImages, setStructureImages] = useState([]);
  const [thumbnail, setThumbnail] = useState('');
  const [heroImage, setHeroImage] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [pin, setPin] = useState('');
  const [canLog, setCanLog] = useState(false);

  const [page, setPage] = useState(0);

  const createRecord = (portfolio) => {
    const portfolioRef = firebase.database().ref('portfolio');
    portfolioRef.push(portfolio);
  };

  const makeUrlId = (title) => {
    return title.replace(/\s+/g, '-').toLowerCase();
  }

  const makeOptionArray = (array) => {
    let objectArray = [];
    for (let i = 0; i < array.length; i++) {
      objectArray.push({ label: array[i], value: array[i] })
    }
    return objectArray;
  }

  const styles = {
    card: {
      display: 'grid',
      placeContent: 'center',
      height: canLog ? '100%' : '100vh',
      padding: '3.2rem',
      fontFamily: 'Poppins',
      background: '#222B3D'
    },
    form: {
      minWidth: 500,
      maxWidth: 800,
      minHeight: 835,
      maxHeight: '100%',
      // margin: '2rem',
      padding: '2rem',
      borderRadius: '1rem',
      background: '#fff',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    h3: {
      marginBottom: '0.1rem'
    },
    formInputs: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem'
    },
    button: {
      marginTop: '1rem'
    },
    steps: {
      display: 'flex',
      justifyContent: 'center',

      '& > div > div': {
        background: 'red'
      }
    }
  }

  const uploadImages = (name, images) => {
    let itemsLooped = 0;
    let uploadImages = [];
    setIsUploading(true);
    images.forEach(image => {
      const formData = new FormData();
      formData.append("file", image)
      formData.append("upload_preset", "ra7gxlxt");

      Axios.post(
        "https://api.cloudinary.com/v1_1/sashenme/image/upload",
        formData
      ).then(response => {
        console.log(response.data)
        uploadImages.push(response.data.secure_url);

        setIsUploading(false);
        itemsLooped++;
        if (itemsLooped === images.length) {
          switch (name) {
            case "featured":
              setFeaturedImages(uploadImages);
              break;
            case "typography":
              setTypographyImages(uploadImages);
              break;
            case "screenshots":
              setScreenshotsImages(uploadImages);
              break;
            case "structures":
              setStructureImages(uploadImages);
              break;
            case "thumbnail":
              setThumbnail(response.data.secure_url);
              break;
            case "heroImage":
              setHeroImage(response.data.secure_url);
              break;
            default:
              break;
          }
        }
      })
    })


  }

  useEffect(() => {
    const categoriesRef = firebase.database().ref('categories');
    categoriesRef.on('value', snapshot => {
      const categoryItems = snapshot.val();
      const categoryList = [];
      for (let id in categoryItems) {
        categoryList.push(categoryItems[id]);
      }
      setCategories(categoryList);
    })
  }, [])

  useEffect(() => {
    if (pin === '4545') {
      setCanLog(true);
    }
  }, [pin])

  return (
    <div style={styles.card}>
      {!canLog ?
        <div>
          <input
            type="text"
            placeholder={'Pass key'}
            name="title"
            style={{ height: 40, width: 300, textAlign: 'center', margin: '0 auto', display: 'block', fontSize: 20 }}
            onChange={val => setPin(val.currentTarget.value)}
            autoFocus
          />
        </div>
        :
        <Formik
          initialValues={{
            title: '',
            description: '',
            type: '',
            isFeature: false,
            categories: [],
            colors: []
          }}
          validate={values => {
            const errors = {};
            if (!values.title) {
              errors.title = 'Required';
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {

            const payload = {
              ...values,
              id: makeUrlId(values.title),
              isFeatured: false,
              categories: values.categories.map(t => t.value),
              colors: values.colors.map(t => t.value),
              heroImage: heroImage,
              imageUrl: thumbnail,
              featureImages: featuredImages,
              structures: structureImages,
              screenshots: screenshotImages,
              typography: typographyImages,

            }

            setTimeout(() => {
              // alert(JSON.stringify(payload, null, 2));
              console.log(payload)
              createRecord(payload)
              setSubmitting(false);
              resetForm();
            }, 400);


          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit} style={styles.form}>

              <div style={styles.formInputs} className="form-inputs">
                <h3 style={styles.h3}>Add portfolio item</h3>
                {isUploading && <div>Uploading...</div>}
                <div className="steps">
                  <div className={page === 0 ? 'active' : ''}>
                    <div>1</div>
                    <span>Hero</span>
                  </div>
                  <div className={page === 1 ? 'active' : ''}>
                    <div>2</div>
                    <span>Theme</span>
                  </div>
                  <div className={page === 2 ? 'active' : ''}>
                    <div>3</div>
                    <span>Assets</span>
                  </div>
                </div>
                {page === 0 &&
                  <>
                    <Input
                      label={'Title'}
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                      error={errors.title}
                      touched={touched.title}
                    />
                    <SelectDropdown
                      label="Categories"
                      name="categories"
                      options={makeOptionArray(categories)}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      value={values.categories}
                    />
                    <Input
                      label="Description"
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                      error={errors.description}
                      touched={touched.description}
                    />
                    <Input
                      label="Website URL"
                      name="website"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.website}
                      error={errors.website && touched.website && errors.website}
                    />
                    <Input
                      label="Type"
                      name="type"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.type}
                      error={errors.type && touched.type && errors.type}
                    />
                    <Input
                      label="Hero Background Color"
                      name="heroBg"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.heroBg}
                      error={errors.heroBg && touched.heroBg && errors.heroBg}
                    />

                    <ImageInput
                      label="Hero Image"
                      id="heroImage"
                      name="heroImage"
                      type="file"
                      imgUrl="https://via.placeholder.com/150x120"
                      onChange={e => uploadImages("heroImage", [e.currentTarget.files[0]])}
                    />
                  </>
                }
                {page === 1 &&
                  <>
                    <Input
                      label="Theme Color"
                      name="themeColor"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.themeColor}
                      error={errors.themeColor}
                    />

                    <CreatableDropdown
                      label="Colors"
                      name="colors"
                      placeholder="Add new"
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      value={values.colors}
                    />
                    <ImageInput
                      label="Thumbnail"
                      name="thumbnail"
                      type="file"
                      imgUrl="https://via.placeholder.com/150x120"
                      onChange={e => uploadImages("thumbnail", [e.currentTarget.files[0]])}
                    />
                    <DropIt
                      label="Typography"
                      uploadImages={(acceptedFiles) => uploadImages('typography', acceptedFiles)}
                      uploadedFiles={typographyImages}
                    />
                  </>
                }
                {page === 2 &&
                  <>
                    <Input
                      label="Screenshots Title"
                      name="screenshotTitle"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.screenshotTitle}
                      error={errors.screenshotTitle}
                    />
                    <DropIt
                      label="Screenshots / Props"
                      uploadImages={(acceptedFiles) => uploadImages('screenshots', acceptedFiles)}
                      uploadedFiles={screenshotImages}
                    />
                    <DropIt
                      label="Featured Images"
                      uploadImages={(acceptedFiles) => uploadImages('featured', acceptedFiles)}
                      uploadedFiles={featuredImages}
                    />
                    <DropIt
                      label="Structures"
                      uploadImages={(acceptedFiles) => uploadImages('structures', acceptedFiles)}
                      uploadedFiles={structureImages}
                    />
                  </>
                }
              </div>
              <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                {page !== 0 &&
                  <Button label="Back" btnType="button" onClick={() => setPage(page - 1)} />
                }
                {page !== 2 &&
                  <>
                    {page === 0 && <div></div>}
                    <Button label="Next" btnType="button" onClick={() => setPage(page + 1)} style={{ alignSelf: 'flex-end' }} />
                  </>}
                {page === 2 &&
                  <Button label={isUploading ? 'Wait...' : 'Submit'} type="secondary" btnType="submit" disabled={isUploading} />
                }
              </div>

            </form>
          )}
        </Formik>
      }
    </div>
  )
}

export default Admin
