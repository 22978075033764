import React from 'react'
import { Link } from 'react-router-dom'

function PortfolioItem({ title, imageUrl, to }) {
  return (
    <Link to={to}>
      <div className="portfolio-item" style={{ backgroundImage: `url(${imageUrl})` }}>
        <div className="portfolio-overlay">
          {title}
        </div>
      </div>
    </Link>
  )
}

export default PortfolioItem
