import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function Button({ label, to, onClick, className, type, target, style, btnType }) {
  return (
    <>
      {to ?
        <Link to={to} className={`btn ${type} ${className}`} target={target} style={style}>
          {label}
        </Link>
        :
        <button className={`btn ${type} ${className}`} type={btnType} onClick={onClick} style={style}>
          {label}
        </button>
      }
    </>
  )
}

export default Button;

Button.propTypes = {
  label: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string
}

Button.defaultProps = {
  type: 'primary'
}