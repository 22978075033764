import React from "react";

export default class Thumb extends React.Component {
  state = {
    loading: false,
    thumb: undefined,
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.file) { return; }

    this.setState({ loading: true }, () => {
      let reader = new FileReader();

      reader.onloadend = () => {
        this.setState({ loading: false, thumb: reader.result });
      };

      reader.readAsDataURL(nextProps.file);
    });
  }

  render() {
    const { file } = this.props;
    const { loading, thumb } = this.state;

    if (!file) { return null; }

    if (loading) { return <p>loading...</p>; }

    return (<div className={this.props.isUploading ? '' : "spinner"}>
      <img src={thumb}
        alt={file.name}
        className="img-thumbnail mt-2"
        style={{ height: 100, borderRadius: 5, border: '1px solid #eee' }}
      />
    </div>);
  }
}