import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyDeMzlu9s8P2RO7JxE1DXxz79DGbZQJZA0",
  authDomain: "sashenme-e29a3.firebaseapp.com",
  projectId: "sashenme-e29a3",
  storageBucket: "sashenme-e29a3.appspot.com",
  messagingSenderId: "126470721043",
  appId: "1:126470721043:web:43c48c4adbc023083139e6",
  measurementId: "G-00Z95LMYZW"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;