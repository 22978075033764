import React from 'react';
import Button from '../Buttons/Button';
function FeatureCard({ title, type, image, to }) {
  return (
    <div className="card feature-portfolio">
      <div className="content">
        <h3>{title}</h3>
        <p>{type}</p>
        <Button label="View Project" to={to} />
      </div>
      <div className="portfolio-image" style={
        { backgroundImage: `url(${image})` }
      }>
      </div>
    </div>
  )
}

export default FeatureCard
