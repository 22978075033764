import React from 'react'

function invision() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35.999" height={36} viewBox="0 0 35.999 36">
      <path id="invision" d="M32.737,0H3.262A3.264,3.264,0,0,0,0,3.262V32.737A3.265,3.265,0,0,0,3.262,36H32.737A3.264,3.264,0,0,0,36,32.738V3.262A3.263,3.263,0,0,0,32.737,0ZM12.03,7a2.163,2.163,0,1,1,0,4.326v.041a2.183,2.183,0,0,1,0-4.366ZM26.754,27.723a2.961,2.961,0,0,1-3.306-3.135,5.85,5.85,0,0,1,.225-1.62l1.065-3.817a3.8,3.8,0,0,0,.165-1.163,1.813,1.813,0,0,0-1.944-1.972c-1.519,0-2.52,1.091-3.037,3.195l-2.07,8.3H14.23l.648-2.61a5.136,5.136,0,0,1-4.365,2.828,2.9,2.9,0,0,1-3.236-3.17,7.547,7.547,0,0,1,.2-1.627v0L9.122,16.2H6.563l.777-2.857h6.15l-2.43,9.607a6.3,6.3,0,0,0-.225,1.479c0,.615.3.8.776.906.292.06,2.588.018,3.84-2.76l1.59-6.375H14.456l.78-2.813h5.58l-.72,3.24C21.067,14.83,23,13.1,24.914,13.1c2.025,0,3.713,1.462,3.713,4.23a7.89,7.89,0,0,1-.36,2.362L27.232,23.4a4.449,4.449,0,0,0-.156.99c0,.651.27.968.741.968s1.1-.36,1.8-2.34l1.414.54c-.833,2.946-2.364,4.161-4.275,4.161Z" />
    </svg>

  )
}

export default invision

