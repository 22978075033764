import React, { useEffect, useState } from 'react';
import ColorDrop from '../components/ColorDrop';
import Nav from '../components/Nav';
import firebase from '../utils/firebase';
import { motion } from 'framer-motion';
import Footer from '../components/Footer';
import { getTextColor } from '../utils/functions';

const transition = { duration: 1, ease: [0.4, 0.01, -0.05, 0.9] };

function SinglePortfolio({ match }) {
  const [portfolio, setPortfolio] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const portfolioRef = firebase.database().ref('portfolio');
    portfolioRef.on('value', snapshot => {
      const porfolioItems = snapshot.val();
      const portfolioList = [];
      for (let id in porfolioItems) {
        portfolioList.push(porfolioItems[id]);
      }
      setPortfolio(portfolioList);
    });
  }, [match.params.id]);

  const imagesLoaded = () => {
    setIsLoading(false)
  }

  const createMarkup = markup => {
    return { __html: markup };
  }
  return (
    <>
      {portfolio.filter(fil => fil.id === match.params.id).map(port =>
        <>
          <Nav bgColor={port.heroBg} themeColor={port.themeColor} />

          {isLoading &&
            <div style={{ zIndex: 5, backgroundColor: '#fff', background: port.heroBg ? port.heroBg : "#FFF", height: '100vh', display: 'grid', placeContent: 'center', position: 'absolute', inset: 0, margin: 'auto' }}>
              {/* <h2 style={{ color: getTextColor(port.heroBg) }}>Loading...</h2> */}
              <div className="big-spinner" style={{ borderColor: port.heroBg ? getTextColor(port.heroBg) : '#ddd', borderTopColor: port.themeColor }}></div>
            </div>
          }
          <motion.div
            className="single-portfolio page">
            <motion.div
              key={port.id}>
              <div style={{ overflow: 'hidden', backgroundColor: port.heroBg ? port.heroBg : 'transparent' }}>
                <motion.section
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ ...transition }}
                  exit={{ opacity: 0 }}
                  id="single-hero" className="main-container">

                  <div className="content">
                    <h2 style={{ color: getTextColor(port.heroBg) }}>{port.title}</h2>
                    <div className="type">{port.categories.toString().replace(/,(?=[^\s])/g, ", ")}</div>
                    {port.description &&
                      <>
                        <h4 style={{ color: getTextColor(port.heroBg) }}>Product Description</h4>
                        <p style={{ color: getTextColor(port.heroBg) }} dangerouslySetInnerHTML={createMarkup(port.description)}></p>
                      </>
                    }
                    {port.website && <a rel="noreferrer" target="_blank" className="btn primary" style={{ backgroundColor: port.themeColor }} href={port.website}>View Website</a>}
                  </div>
                  <div className="hero-image" style={port.heroStyle}>
                    {port.heroImage && <img src={port.heroImage} alt={port.title} onLoad={imagesLoaded} />}
                  </div>
                </motion.section>
              </div>
              {port.featureImages &&
                <section id="feature-images">
                  {port.featureImages.map((img, id) =>
                    <img src={img} key={id} alt={id} />
                  )}
                </section>
              }
              {port.structures &&
                <section id="structures" className="main-container">
                  <h3>Structure</h3>
                  <div className="structures">
                    {port.structures.map((img, id) =>
                      <img src={img} key={id} alt={id} />
                    )}
                  </div>
                </section>
              }
              {port.typography &&
                <section id="typography" className="main-container">
                  <h3>Typography</h3>
                  <>
                    {port.typography.map((img, id) =>
                      <img src={img} key={id} alt={id} />
                    )}
                  </>
                </section>
              }

              {port.colors &&
                <section id="colors" className="main-container">
                  <h3>Colors</h3>
                  <div className="color-pallet">
                    {port.colors.map((color, id) =>
                      <ColorDrop key={id} color={color} />
                    )}
                  </div>
                </section>
              }

              {port.screenshots &&
                <section id="screenshots" className="main-container">
                  <h3>{port.screenshotTitle ? port.screenshotTitle : 'High-Fidelity Mockup'}</h3>
                  <div className="screenshots">
                    {port.screenshots.map((img, id) =>
                      <img src={img} key={id} alt={id} />
                    )}
                  </div>
                </section>
              }
            </motion.div>
          </motion.div>
          <Footer />
        </>
      )}

    </>
  )
}

export default SinglePortfolio
