import React from 'react'

function lightroom() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={36} height="35.1" viewBox="0 0 36 35.1">
      <path id="adobelightroom" d="M9.973,24.938c-.174,0-.223-.075-.223-.222V8.6c0-.123.05-.2.174-.2H12.3c.124,0,.15.05.15.173v13.89h6.188c.124,0,.159.05.135.173L18.4,24.764a.2.2,0,0,1-.222.174Zm11.1-9.714c0-.174,0-.619-.074-1.461,0-.123.027-.15.126-.2a15.834,15.834,0,0,1,5.514-1.039c.123,0,.173.026.173.15V14.85c0,.124-.05.15-.173.15a8.687,8.687,0,0,0-2.865.3v9.466c0,.124-.05.174-.173.174H21.248c-.124,0-.174-.05-.174-.174ZM0,.45v35.1H36V.45Zm1.5,1.5h33v32.1H1.5Z" transform="translate(0 -0.45)" />
    </svg>

  )
}

export default lightroom

