import React from 'react'

function illustrator() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={36} height="35.1" viewBox="0 0 36 35.1">
      <path id="adobeillustrator" d="M0,.45v35.1H36V.45Zm1.5,1.5h33v32.1H1.5ZM12.488,20.325l-1.188,4.5c-.026.124-.075.153-.222.153h-2.2c-.15,0-.173-.05-.15-.222l4.26-14.918a4.626,4.626,0,0,0,.147-1.249c0-.1.05-.15.124-.15H16.4c.1,0,.15.027.174.15l4.777,16.191c.026.124,0,.2-.124.2H18.752c-.124,0-.2-.03-.222-.129l-1.237-4.522h-4.81Zm4.182-2.438c-.42-1.659-1.41-5.292-1.782-7.05h-.026c-.321,1.755-1.113,4.725-1.732,7.05ZM23.42,9.55a1.534,1.534,0,0,1,3.068,0,1.437,1.437,0,0,1-1.56,1.534A1.41,1.41,0,0,1,23.413,9.55Zm.168,3.411c0-.12.05-.165.174-.165h2.355c.132,0,.18.05.18.174V24.812c0,.124-.024.174-.173.174H23.8c-.15,0-.2-.075-.2-.195V12.96Z" transform="translate(0 -0.45)" />
    </svg>

  )
}

export default illustrator

