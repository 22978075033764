
import {
  Switch,
  Route,
  useLocation
} from 'react-router-dom';
import * as Views from './views';
import './App.scss';
import { AnimatePresence } from 'framer-motion';
function App() {
  const location = useLocation();

  return (
    <>
      <AnimatePresence exitBeforeEnter >
        <Switch location={location} key={location.pathname}>
          <Route path="/" exact={true} component={Views.Home} />
          <Route path="/portfolio" exact={true} component={Views.Portfolio} />
          <Route path="/my-story" component={Views.About} />
          <Route path="/lets-talk" component={Views.Contact} />
          <Route path="/portfolio/:id" component={Views.SinglePortfolio} />
          <Route path="/admin" component={Views.Admin} />
        </Switch>
      </AnimatePresence>
    </>
  );
}

export default App;
