import React from 'react'

function Footer({ style }) {
  return (
    <footer style={style}>
      &copy; 2021 Sashen Pasindu
    </footer>
  )
}

export default Footer
