import React, { useEffect, useState } from 'react';
import Button from '../components/Buttons/Button';
import FeatureCard from '../components/Cards/FeatureCard';
import ArrowDown from '../icons/ArrowDown';
import HeroImage from '../images/sashen.png';
import firebase from '../utils/firebase';
import github from '../images/icons/github.svg';
import twitter from '../images/icons/twitter.svg';
import linkedin from '../images/icons/linkedin.svg';
import Nav from '../components/Nav';
import { motion } from 'framer-motion';
import Footer from '../components/Footer';
import PortfolioPlaceholder from '../components/Portfolio/PortfolioPlaceholder';




const transition = { duration: 0.9, ease: [0.6, 0.01, -0.05, 0.9] };
const firstName = {
  initial: {
  },
  animate: {
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.01,
      staggerDirection: 1,
    }
  }
}
const lastName = {

  animate: {
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.01,
      staggerDirection: 1,
    }
  }
}
const letter = {
  initial: {
    y: 200,
    display: 'inline-block',
    opacity: 0,
  },
  animate: {
    y: 0,
    display: 'inline-block',
    opacity: 1,
    transition: { duration: 0.2, ...transition }
  }
}


function Home() {
  const [portfolio, setPortfolio] = useState([]);

  useEffect(() => {
    const portfolioRef = firebase.database().ref('portfolio');
    portfolioRef.on('value', snapshot => {
      const porfolioItems = snapshot.val();
      const portfolioList = [];
      for (let id in porfolioItems) {
        portfolioList.push(porfolioItems[id]);
      }
      setPortfolio(portfolioList);
    });
  }, [])
  return (
    <motion.div

    >
      <Nav />
      <motion.section
        initial={{ y: 0, opacity: 0, }}
        animate={{ opacity: 1, }}
        exit={{ opacity: 0 }}
        transition={transition}
        id="hero">
        <div>
          <div className="main-container">
            <motion.div className="content">
              <motion.h4
                initial={{ opacity: 0, y: 200 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.02, ...transition }}
                className="name">Hi! I'm Sashen Pasindu</motion.h4>
              <motion.h1

              >
                <motion.span
                  initial='initial'
                  animate='animate'
                  exit='exit'
                  variants={firstName}
                  style={{ display: 'block' }}>
                  <motion.span key={1} variants={letter}>U</motion.span>
                  <motion.span key={2} variants={letter}>I</motion.span>
                  <motion.span key={3} variants={letter}>/</motion.span>
                  <motion.span key={4} variants={letter}>U</motion.span>
                  <motion.span key={5} variants={letter}>X</motion.span>
                  <motion.span key={6}> </motion.span>
                  <motion.span key={7} variants={letter}>D</motion.span>
                  <motion.span key={8} variants={letter}>e</motion.span>
                  <motion.span key={9} variants={letter}>s</motion.span>
                  <motion.span key={10} variants={letter}>i</motion.span>
                  <motion.span key={11} variants={letter}>g</motion.span>
                  <motion.span key={12} variants={letter}>n</motion.span>
                  <motion.span key={13} variants={letter}>e</motion.span>
                  <motion.span key={14} variants={letter}>r</motion.span>
                </motion.span>
                <motion.span initial='initial'
                  animate='animate'
                  exit='exit'
                  variants={lastName}>
                  <motion.span key={1} variants={letter}>W</motion.span>
                  <motion.span key={2} variants={letter}>e</motion.span>
                  <motion.span key={3} variants={letter}>b</motion.span>
                  <motion.span key={4} > </motion.span>
                  <motion.span key={5} variants={letter}>D</motion.span>
                  <motion.span key={6} variants={letter}>e</motion.span>
                  <motion.span key={7} variants={letter}>v</motion.span>
                  <motion.span key={8} variants={letter}>e</motion.span>
                  <motion.span key={9} variants={letter}>l</motion.span>
                  <motion.span key={10} variants={letter}>o</motion.span>
                  <motion.span key={11} variants={letter}>p</motion.span>
                  <motion.span key={12} variants={letter}>e</motion.span>
                  <motion.span key={13} variants={letter}>r</motion.span>
                </motion.span>
              </motion.h1>
              <motion.h4
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6, ...transition }}
                className="experience">with 9 years of experience</motion.h4>
              <div className="btn-group">
                <motion.span
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.65, ...transition }}>
                  <Button to={'/portfolio'} label="Portfolio" className={"large"} />
                </motion.span>
                <motion.span
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.7, ...transition }}>
                  <Button to={'/lets-talk'} label="Let's Talk" type="secondary" className={"large"} />
                </motion.span>
              </div>
            </motion.div>
            <div className="hero-image">
              <motion.img whileHover={{ scale: 1.03 }} src={HeroImage} alt="Sashen and Coco" />
            </div>

          </div>
          <a href="#latest-projects" id="latest-projects">
            <ArrowDown className="arrow-down" />
          </a>
        </div>
      </motion.section>
      <section id="featured-portfolio">
        <div className="card-container">
          <h3 className="main-heading">Latest projects</h3>
          <div className="cards-wrapper">
            {portfolio.length > 0 ?
              portfolio.filter(item => item.isFeatured === true).map(item =>
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  key={item.id}
                >
                  <FeatureCard
                    key={item.id}
                    title={item.title}
                    type={item.categories.toString().replace(/,(?=[^\s])/g, ", ")}
                    image={item.imageUrl}
                    to={`/portfolio/${item.id}`}
                  />
                </motion.div>
              )
              :
              <>
                <PortfolioPlaceholder />
                <PortfolioPlaceholder />
                <PortfolioPlaceholder />
              </>

            }
          </div>
        </div>
        <div className="footer-container">
          <h3>Interested to work with me?</h3>
          <Button label="Let's Talk" type="secondary" className="large" to="/lets-talk" />
          <ul className="social-links">
            <li>
              <a href="https://github.com/sashenme" target="_blanks">
                <img src={github} alt="Github" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/sashenme" target="_blanks">
                <img src={twitter} alt="Twitter" />
              </a>
            </li>
            <li>
              <a href="https://linkedin.com/in/sashenpasindu" target="_blanks">
                <img src={linkedin} alt="LinkedIn" />
              </a>
            </li>
          </ul>

          <Footer style={{ color: 'white' }} />
        </div>

      </section>
    </motion.div>
  )
}

export default Home;
