import React from 'react'
import CreatableSelect from 'react-select/creatable';

function CreatableDropdown(props) {
  const handleChange = (newValue: any) => {
    props.onChange(props.name, newValue);
  };
  const handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topcis
    props.onBlur(props.name, true);
  };
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',

    }}>
      <label htmlFor={props.name} style={{
        fontSize: 14,
        marginBottom: 5,
        fontWeight: 500
      }}>{props.label}</label>
      <CreatableSelect
        isMulti
        placeholder={props.placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        options={props.options}
      />
      {!!props.error &&
        props.touched && (
          <div style={{ color: 'red', marginTop: '.5rem' }}>{props.error}</div>
        )}
    </div>
  )
}

export default CreatableDropdown
