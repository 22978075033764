import React from 'react'

function bootstrap() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27.01" height="27.01" viewBox="0 0 27.01 27.01">
      <path id="bxl-bootstrap" d="M27,4.495H9A4.517,4.517,0,0,0,4.5,9V27a4.518,4.518,0,0,0,4.5,4.5H27A4.518,4.518,0,0,0,31.5,27V9A4.518,4.518,0,0,0,27,4.5Zm-2.46,18.971A3.6,3.6,0,0,1,23.5,24.857a5.391,5.391,0,0,1-1.809.921,8.98,8.98,0,0,1-2.625.344H11.832V10.364h7.654a5.471,5.471,0,0,1,3.4.969,3.458,3.458,0,0,1,1.281,2.937,3.491,3.491,0,0,1-.584,2.035,3.759,3.759,0,0,1-1.667,1.3v.051a3.4,3.4,0,0,1,2.2,1.368,4.608,4.608,0,0,1,.74,2.683,4.489,4.489,0,0,1-.338,1.72l.03.032v0Zm-3.126-3.883a2.775,2.775,0,0,0-1.946-.628H14.6v4.914h4.88a2.732,2.732,0,0,0,1.947-.652,2.411,2.411,0,0,0,.7-1.844,2.3,2.3,0,0,0-.7-1.79h-.015ZM20.741,16.4a2,2,0,0,0,.666-1.626,2.045,2.045,0,0,0-.6-1.674,2.673,2.673,0,0,0-1.719-.489h-4.5v4.347h4.5a2.476,2.476,0,0,0,1.648-.558Z" transform="translate(-4.495 -4.495)" />
    </svg>

  )
}

export default bootstrap
