import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import Thumb from './Thumb'

function DropIt({ uploadImages, label, uploadedFiles }) {
  const [selectedImages, setSelectedImages] = useState([])
  const styles = {
    dropzone: {
      width: "100%",
      height: "auto",
      borderWidth: 2,
      borderColor: "#ccc",
      borderStyle: "dashed",
      borderRadius: 5,
      fontSize: 12,
      marginTop: 5
    },
    label: {
      fontSize: 14,
      marginBottom: 5,
      fontWeight: 500
    },
    dropText: {
      fontSize: 12,
      color: '#bfbfbf',
      textAlign: 'center',
      padding: '2rem'
    },
    thumbs: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '0.5rem',
      padding: '0.5rem'
    }
  }

  const handleDrop = (e) => {
    setSelectedImages(e);
    uploadImages(e);
  }

  return (
    <div className="form-group">
      <label style={styles.label}>{label}</label>
      <Dropzone accept="image/*" onDrop={(acceptedFiles) => handleDrop(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <section style={styles.dropzone} >
            {selectedImages.length === 0 ?
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p style={styles.dropText}>Drag 'n' drop some files here, or click to select files</p>
              </div>
              :
              <div style={styles.thumbs}>
                {selectedImages.map((file, i) => (<Thumb key={i} file={file} isUploading={uploadedFiles.length > 0} />))}
              </div>
            }
          </section>
        )}
      </Dropzone>
      <ul style={{ fontSize: 10, padding: 10 }}>
        {uploadedFiles.length > 0 &&
          uploadedFiles.map(img => <li key={img} >{img}</li>)
        }
      </ul>
    </div>
  )
}

export default DropIt
