const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export function getTextColor(color) {
  if (color) {
    const rgb = hexToRgb(color);
    const o = Math.round(((parseInt(rgb.r) * 299) + (parseInt(rgb.g) * 587) + (parseInt(rgb.b) * 114)) / 1000);
    if (o > 125) {
      return '#373737'
    } else {
      return '#fff'
    }
  } else {
    return '#373737'
  }

}