import React, { useState } from 'react';
import Thumb from './Thumb';

function ImageInput({ label, onChange, onBlur, name, value, error, touched, type = "text", imgUrl }) {
  const [thumb, setThumb] = useState();

  const styles = {
    inputGroup: {
      display: 'flex',
      flexDirection: 'column',
      width: type === 'file' ? 'unset' : '100%'
    },
    input: {
      height: 40,
      border: type === "file" ? 'none' : '1px solid #ccc',
      borderRadius: 5,
      padding: type === "file" ? '0.5rem 0' : '0.5rem',
      width: '100%'
    },
    label: {
      fontSize: 14,
      marginBottom: 5,
      fontWeight: 500
    },
    error: {
      color: 'red',
      fontSize: 10,
      marginTop: 5
    },
    inputFile: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    img: {
      borderRadius: 6,
      height: 90,
      display: imgUrl ? 'block' : 'none'
    }
  }

  const handleChange = e => {
    onChange(e);
    setThumb(e.currentTarget.files[0]);
  }
  return (
    <div style={styles.inputFile}>
      <div className="input-group" style={styles.inputGroup}>
        <label htmlFor={name} style={styles.label}>{label}</label>
        <input
          style={styles.input}
          id={name}
          type={type}
          name={name}
          onChange={(e) => handleChange(e)}
          onBlur={onBlur}
          value={value}
        />
        {!!error &&
          touched && (
            <div style={styles.error}>{error}</div>
          )}
      </div>
      {type === "file" &&
        <Thumb file={thumb} />
      }
    </div>
  )
}

export default ImageInput
