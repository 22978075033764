import React, { useEffect, useState } from 'react'
import { getTextColor } from '../utils/functions';

function ColorDrop({ color }) {
  const [textColor, setTextColor] = useState('#fff');



  useEffect(() => {
    setTextColor(getTextColor(color))
  }, [color])

  return (
    <div className="color-drop" style={{ backgroundColor: color, color: textColor }}>
      {color}
    </div>
  )
}

export default ColorDrop
