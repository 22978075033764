import React, { useEffect, useState } from "react";
import ReactIcon from "../icons/react";
import LaravelIcon from "../icons/laravel";
import SassIcon from "../icons/sass";
import NodeIcon from "../icons/node";
import BootstrapIcon from "../icons/bootstrap";
import JqueryIcon from "../icons/jquery";
import PhotoshopIcon from "../icons/photoshop";
import IllustratorIcon from "../icons/illustrator";
import LightroomIcon from "../icons/lightroom";
import XdIcon from "../icons/xd";
import FigmaIcon from "../icons/figma";
import InvisionIcon from "../icons/invision";
import Nav from "../components/Nav";
import { motion } from "framer-motion";
import Footer from "../components/Footer";
import firebase from "../utils/firebase";

function About() {
  const initialData = {
    image:
      "https://res.cloudinary.com/sashenme/image/upload/v1663888362/sashen_and_coco_gv6s4f.png",
    paragraph:
      "I’m Sashen Pasindu. I’m a UI/UX designer and a full-stack developer with 9 years of experience. Currently living in Stockholm, Sweden",
  };
  const [aboutData, setAboutData] = useState(initialData);

  useEffect(() => {
    const aboutRef = firebase.database().ref("about");
    aboutRef.on("value", (snapshot) => {
      setAboutData(snapshot.val());
    });
  }, []);

  const transition = { duration: 0.9, ease: [0.6, 0.01, -0.05, 0.9] };
  const icons = [
    { icon: <ReactIcon />, name: "React.js", type: "dev" },
    { icon: <LaravelIcon />, name: "Laravel", type: "dev" },
    { icon: <NodeIcon />, name: "Node.js", type: "dev" },
    { icon: <BootstrapIcon />, name: "Bootstrap", type: "dev" },
    { icon: <SassIcon />, name: "Sass", type: "dev" },
    { icon: <JqueryIcon />, name: "jQuery", type: "dev" },
    { icon: <PhotoshopIcon />, name: "Photoshop", type: "des" },
    { icon: <IllustratorIcon />, name: "Illustrator", type: "des" },
    { icon: <LightroomIcon />, name: "Lightroom", type: "des" },
    { icon: <XdIcon />, name: "AdobeXd", type: "des" },
    { icon: <FigmaIcon />, name: "Figma", type: "des" },
    { icon: <InvisionIcon />, name: "inVision", type: "des" },
  ];
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={transition}
    >
      <Nav />
      <motion.section id="my-story" className="page">
        <h3 className="main-heading">My Story</h3>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, ...transition }}
          className="main-container"
        >
          <div className="content">
            <p>{aboutData.paragraph}</p>
            {/* <p>Also, I have a dachshund puppy called Coco</p> */}
            <p>I have experience in;</p>
            <h5>Development</h5>
            <div className="icon-flex">
              {icons
                .filter((icon) => icon.type === "dev")
                .map((icon, id) => (
                  <div className="icon" key={id}>
                    {icon.icon}
                    <span>{icon.name}</span>
                  </div>
                ))}
            </div>
            <h5>Design</h5>
            <div className="icon-flex">
              {icons
                .filter((icon) => icon.type === "des")
                .map((icon, id) => (
                  <div className="icon" key={id}>
                    {icon.icon}
                    <span>{icon.name}</span>
                  </div>
                ))}
            </div>
          </div>
          <div className="hero-image">
            <img src={aboutData.image} alt="Sashen and Coco" />
          </div>
        </motion.div>
      </motion.section>
      <Footer />
    </motion.div>
  );
}

export default About;
