import React from 'react'

function node() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31.924" height={36} viewBox="0 0 31.924 36">
      <path id="node-dot-js" d="M18,36a2.764,2.764,0,0,1-1.383-.37l-4.4-2.606c-.657-.367-.336-.5-.12-.575a8.812,8.812,0,0,0,1.992-.906.332.332,0,0,1,.327.026l3.384,2.008a.435.435,0,0,0,.408,0l13.192-7.614a.416.416,0,0,0,.2-.357V10.382a.424.424,0,0,0-.206-.363L18.2,2.411a.417.417,0,0,0-.407,0L4.613,10.02a.426.426,0,0,0-.209.361V25.606a.4.4,0,0,0,.209.352l3.613,2.088c1.96.981,3.162-.174,3.162-1.335V11.681a.379.379,0,0,1,.384-.38h1.672a.382.382,0,0,1,.383.38V26.713c0,2.618-1.425,4.117-3.906,4.117A5.638,5.638,0,0,1,6.882,30L3.42,28.013a2.784,2.784,0,0,1-1.383-2.406V10.382a2.783,2.783,0,0,1,1.383-2.4L16.612.354a2.888,2.888,0,0,1,2.772,0L32.575,7.977a2.785,2.785,0,0,1,1.386,2.4V25.607a2.79,2.79,0,0,1-1.386,2.406L19.384,35.63A2.758,2.758,0,0,1,18,36ZM28.649,20.989c0-2.85-1.926-3.609-5.981-4.144-4.1-.541-4.513-.822-4.513-1.78,0-.792.352-1.85,3.387-1.85,2.71,0,3.709.584,4.12,2.41a.381.381,0,0,0,.37.3h1.712a.39.39,0,0,0,.279-.121.386.386,0,0,0,.1-.294c-.265-3.147-2.357-4.614-6.582-4.614-3.762,0-6.006,1.587-6.006,4.25,0,2.888,2.232,3.685,5.843,4.042,4.32.423,4.655,1.054,4.655,1.9,0,1.474-1.183,2.1-3.963,2.1-3.49,0-4.258-.876-4.517-2.613a.383.383,0,0,0-.38-.323H15.468a.378.378,0,0,0-.381.38c0,2.223,1.209,4.872,6.982,4.872,4.182,0,6.579-1.644,6.579-4.519Z" transform="translate(-2.037 0)" />
    </svg>

  )
}

export default node

