import React, { useEffect, useState } from 'react';
import Button from '../components/Buttons/Button';
import Nav from '../components/Nav';
import PortfolioItem from '../components/Portfolio/PortfolioItem';
import firebase from '../utils/firebase';
import { motion } from 'framer-motion';
import Footer from '../components/Footer';

const transition = { duration: 0.6, ease: [0.4, 0.01, -0.05, 0.9] };
const portfolioParent = {
  animate: {
    transition: {
      delay: 0,
      delayChildren: 0.2,
      staggerChildren: 0.04,
      staggerDirection: 1
    }
  }
}
const portfolioChild = {
  initial: {
    y: 100,
    opacity: 0
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5, ...transition
    }
  }
}
const portfolioPlaceholder = {
  initial: {
    y: 100,
    opacity: 0
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5, ...transition
    },

  }
}
function Portfolio() {
  const [categories, setCategories] = useState([]);
  const [portfolio, setPortfolio] = useState([]);
  const [currentCategory, setCurrentCategory] = useState('All')
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const categoriesRef = firebase.database().ref('categories');
    categoriesRef.on('value', snapshot => {
      const categoryItems = snapshot.val();
      const categoryList = [];
      for (let id in categoryItems) {
        categoryList.push(categoryItems[id]);
      }
      setCategories(categoryList);
    })
    const portfolioRef = firebase.database().ref('portfolio');
    portfolioRef.on('value', snapshot => {
      const porfolioItems = snapshot.val();
      const portfolioList = [];
      for (let id in porfolioItems) {
        portfolioList.push(porfolioItems[id]);
      }
      setPortfolio(portfolioList);
      setIsLoading(false);
    });

  }, [])

  const filterByCategory = cat => {
    setCurrentCategory(cat);

  }
  return (
    <motion.div initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={transition}>
      <Nav />
      <motion.section

        id="portfolio" className="page">
        <h3 className="main-heading">Portfolio</h3>
        <ul className="category-list">
          <li>
            <Button
              label={'All'}
              type="transparent"
              onClick={() => filterByCategory('All')}
              className={currentCategory === 'All' ? 'active' : ''} />
          </li>
          {categories.map((cat, i) =>
            <li key={i}>
              <Button
                label={cat}
                type="transparent"
                onClick={() => filterByCategory(cat)}
                className={currentCategory === cat ? 'active' : ''}
              />
            </li>
          )}
        </ul>
        <motion.div
          initial='initial'
          animate='animate'
          exit='exit'
          variants={portfolioParent}
          className="portfolio-grid main-container">
          {isLoading ?
            <>
              <motion.div variants={portfolioPlaceholder} key={1} className="portfolio-item placeholder"></motion.div>
              <motion.div variants={portfolioPlaceholder} key={2} className="portfolio-item placeholder"></motion.div>
              <motion.div variants={portfolioPlaceholder} key={3} className="portfolio-item placeholder"></motion.div>
              <motion.div variants={portfolioPlaceholder} key={4} className="portfolio-item placeholder"></motion.div>
              <motion.div variants={portfolioPlaceholder} key={5} className="portfolio-item placeholder"></motion.div>
              <motion.div variants={portfolioPlaceholder} key={6} className="portfolio-item placeholder"></motion.div>
              <motion.div variants={portfolioPlaceholder} key={7} className="portfolio-item placeholder"></motion.div>
              <motion.div variants={portfolioPlaceholder} key={8} className="portfolio-item placeholder"></motion.div>
            </>
            :
            portfolio.filter(filter => currentCategory !== 'All' ? filter.categories.includes(currentCategory) : true
            ).map((portfolio, i) =>
              <motion.div
                whileHover={{ zIndex: 1, scale: 1.2, boxShadow: '0 2px 16px rgba(0,0,0,0.45)' }}
                variants={portfolioChild}
                key={i}
                style={{ borderRadius: '0.5rem' }}
              >
                <PortfolioItem
                  to={`/portfolio/${portfolio.id}`}

                  title={portfolio.title}
                  imageUrl={portfolio.imageUrl}
                  type={portfolio.type.split(',')}
                />
              </motion.div>
            )}
        </motion.div>
      </motion.section>
      <Footer />
    </motion.div>
  )
}

export default Portfolio
